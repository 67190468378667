import { Paper, Typography } from '@mui/material';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend, ArcElement,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { QuestData1 } from '../dumy-data';

import { gen_color_palette } from '../shitty-lib/gen-pallete';
import { MiniPaginator } from '../shitty-lib/mini-paginator';
import { getProgressAll, getProgressByQuest } from '../Utils/requests';

// add all "bricks" required for used charts(Bar, Pie, ...)
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const options = {
  // for stacking. y-stacking disabled - to start growing from y=0, not from head of previous bar
  scales: {
    x: {
      stacked: true,
      display: false
    },
    y: {
      stacked: true,
      display: true
    },
  },


  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Прогресс выполнения заданий',
    },
  },
};


async function getProgress(setData, Params={}) {
  let data = await getProgressAll(Params)
  data = data.data  // data Object - not array!!!
  const keys = Object.keys(data)
  setData({
    labels: keys,
    quest_IDs: keys.map((name) => data[name].quest_id), 
    dataNorm: keys.map((name) => data[name].quest_true_answer_count),
    dataBad:  keys.map((name) => data[name].quest_false_answer_count),
  })
}

async function getQuestErrors(setData, Params={}) {
  let data = await getProgressByQuest(Params)
  data = data.data  // data Object - not array!!!
  const keys = Object.keys(data)
  setData(
    keys.map((name, i) => ({
      percent: Math.round(data[name].quest_false_answer_count/data[name].quest_true_answer_count)*100,
      label: keys[i]
    }))
  )
}

export const ChartsBar = () => {
  const [page, setPage] = useState(1)
  const [AllProgress, setAllProgress] = useState({
    labels: [''],
    quest_IDs: [],
    dataNorm: [],
    dataBad: [],
  })

  const [questErrors, setQuestErrors] = useState( [{percent: null, label: ''}] )

  useEffect(() => {
    getProgress(setAllProgress)
    getQuestErrors(setQuestErrors, {quest_id: AllProgress.quest_IDs[page-1]})
  }, [])  

  const {labels, dataNorm, dataBad} = AllProgress
  
  const data = {
    labels,
    datasets: [
      {
        label: 'удовлетворительных ответов (≥60%)',
        data: dataNorm,
        backgroundColor: 'rgba(220, 123, 18, 0.87)',
        order: 2
      },
      {
        label: 'неудовлетворительных ответов (<60%)',
        data: dataBad,
        backgroundColor: 'rgba(231, 3, 3, 0.9)', //0.62
        order: 1
      },
    ],
  }
  
  
  
  
  ChartJS.register(ArcElement);
  const pieData = [{
    labels: questErrors.map(({label}) => label),
    datasets: questErrors.map(({percent, label})=>({
      data: percent,
      
      backgroundColor: gen_color_palette([label], 0.55).backGround,
      borderColor: gen_color_palette([label], 0.55).border,
      borderWidth: 1
    })), 
  }]
  
  
  const PiePlugins = {
    legend: {
      display: false
    },
    title: {
      display: false,
      text: `Должники в задании "${labels[page-1]}"`,

    },
  }
  
  // Добавиить возможность при клике на сектор перейти к нужной группе

  return <Paper style={{ display: 'flex', justifyContent: 'start', rowGap: '2rem',
    width: '50%', marginTop: '3.78rem', padding: '1rem',
    alignItems: 'center', flexDirection: 'column',
    }}>

    <div style={{width: '41rem'}}>
      <Bar options={options} data={data} />
    </div>


    <Typography variant='subtitle' height='2rem' overflow='hidden' sx={{width: '37rem', mb: '-1rem'}}>
        {`Доля студентов, неуспевающих в задании: "${labels[page-1]}"`}
    </Typography>

    <MiniPaginator
      content= <div style={{width: '23rem'}}>
        <Pie options={{plugins: PiePlugins}} data={pieData[page-1]} />
      </div>

      PagParams={
      {
        count: pieData.length,
        page: page,
        onChange: (e, page) => setPage(page)
      }}
      style={{width: '30rem'}}
    />

  </Paper>

}

export const GroupsChartsBar = () => {
  return <ChartsBar/>
}
