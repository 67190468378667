import { Box, Input, Paper, rgbToHex, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  Title
} from 'chart.js';
import { useEffect, useState } from 'react';

import { Radar, Line } from 'react-chartjs-2';
import { InteractiveTable } from '../../ContentTable/table';
import { ColumnContainer } from '../../shitty-lib';
import { getProgressByStudent } from '../../Utils/requests';


ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Title,
  Legend
);

const kvbo0419_TrueAnsPerQuests = [5, 7, 5, 6]
const kvbo0419_Debths = [
  {student_id: 'ad3dcc8c-25cd-4a41-bd30-e4790660f99f', student_full_name: 'Осиповаа Ольга Максимовна'},
  {student_id: '8c943a39-db3d-4d74-93ba-de14a75a6dbb', student_full_name: 'Пётр Александрович Самсонова'},
  {student_id: '5371a07c-4e89-4b2f-b2d4-961a775d51f0', student_full_name: 'Устинова Антонина Борисовна'},
  {student_id: '08c71033-d9ca-4401-8c00-6d3468abb58f', student_full_name: 'Дементьева Клавдия Львовна'},

  {student_id: '3bbe1ed3-d6e2-4973-986b-7aef519d7241', student_full_name: 'Вероника Александровна Ивановаа'},
  {student_id: '627d29b3-a249-4668-9453-a4d6137a38b0', student_full_name: 'Богдан Алексеевич Попова'},
  {student_id: '45d41f76-4d6e-4615-921d-7667c31bf5c3', student_full_name: 'Сафоноваа Зоя Евгеньевна'},
  {student_id: '10bf7cf7-4bf6-45c0-b814-d28459f5f431', student_full_name: 'Харитонов Святослав Евгеньевич'},
]




const SolovedData = {
  labels: ['№ 1', '№ 2', '№ 3', '№ 4'],
  datasets: [
    {
      label: 'справившихся студентов',
      data: kvbo0419_TrueAnsPerQuests,
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
    },
  ],
};

const options =  {
  scales: {
    r: {
        suggestedMin: 0,
        suggestedMax: 9  // student in group
    }  
  },

  plugins: {
    legend: {
      display: false
    },
    clip: 0
  }
  
}



const SimpleTable = ({Content, style}) => {
    
  return <TableContainer component={Paper} className="noscroll" style={{ height: '10rem', ...style}}>
      <Table>
        <TableBody> 
         {Content}
        </TableBody>
      </Table>
    </TableContainer>
}

const FromObjectsTable = ({ObjectsArr, poles, onRowClick, style}) => {
  const ComplexData = ObjectsArr.map((row, idx)=> <TableRow 
    onClick={(e) => onRowClick(idx)} hover sx={{cursor: 'pointer'}}>
    {poles.map((pole)=> <TableCell> 
        {`${idx+1}. `}
        <Typography variant='content'>  
          {row[pole]}
        </Typography> 
      </TableCell>
    )}
    </TableRow>)
  
  return <SimpleTable Content={ComplexData} style={{...style}}/>
}

const FromArrayTable = ({Array, style}) => <SimpleTable 
  Content={
    Array.map((el, idx) => <TableRow 
      hover sx={{cursor: 'pointer'}}>
      
      {`№${idx+1}. `}
      <Typography variant='content'>  
        {el}
      </Typography> 
    </TableRow>)} 
  
  style={{...style}}/> 


async function GetProgressByStudent (id, setData) {
  let progress = await getProgressByStudent(id)
  const percents = progress.data.student_quests.map(({quest_total_tasks_count, quest_true_answer_count})=>{
    return quest_total_tasks_count !== 0 ? Math.round((quest_true_answer_count/quest_total_tasks_count)*100) : 100
  })
  setData({...progress.data, percents})
}

export const StudentsChartsBar = (Progress) => {

  const [border, setBorder] = useState(60.0)
  const [chosenStudentIdx, setChosenStudentIdx] = useState(0) // chose 0th student with debth by default
  const [chosenData, setChosenData] = useState({})

  let successed = []
  let debth = []

  debth = kvbo0419_Debths
  
  // handling non-empty init
  if (debth !== [] && Object.keys(chosenData) === []){
    GetProgressByStudent(debth[0].student_id, setChosenData)
  }

  useEffect(() => {
    GetProgressByStudent(debth[chosenStudentIdx].student_id, setChosenData) 
  }, [chosenStudentIdx])
  


  const DebthData = {
    labels: chosenData.percents ? chosenData.percents.map((el, idx)=>`№ ${idx+1}`) : '',
    datasets: [
      {
        label: chosenData.student_full_name,
        data: chosenData.percents,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const questNames = [
    'Размещение лекарственных препаратов и товаров аптечного ассортимента при приеме товара в аптеке', 
    'Знакомство с должностью заведующего аптекой и оформление уголка потребителя', 
    'Знакомство с должностными обязанностями сотрудников аптеки', 
    'Санитарный режим в аптечном учреждении']
  

  return <Paper style={{ display: 'flex', justifyContent: 'space-between', rowGap: '2rem',
    width: '50%', marginTop: '4.5rem', padding: '1rem', 
    alignItems: 'start', flexDirection: 'column'}}>

    <Box style={{ display: 'flex'}}>
      <div style={{width: '30.5rem', marginRight: '0.5rem'}}>
      <Typography variant="subtitle">Решённые задания:</Typography>
        <Radar options={options} data={SolovedData}/>
      </div>                

      <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        
        <Box>
          <Typography variant="subtitle">Список заданий</Typography>
          <FromArrayTable Array={questNames} style={{width: '22rem', height: '25rem', border: '1px solid gray', padding: '4px'}}/>
        </Box>
        
        
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Typography variant="subtitle">Порог, %</Typography>
          <Input onChange={(e)=>setBorder(e.target.value)}
            type="number"
            placeholder={border} />
        </Box>
      </Box>
    </Box>

    <Box style={{ display: 'flex', justifyContent: 'space-between'}}>        
      <ColumnContainer>
        <Typography noWrap={true} variant="subtitle" sx={{mr: '5rem'}}>Студенты, имеющие задолжность({debth.length}):</Typography>
        <FromObjectsTable ObjectsArr={debth} ComplexData={debth} poles={['student_full_name']} onRowClick={(idx)=>setChosenStudentIdx(idx)}/>
      </ColumnContainer>

      <div style={{width: '29rem'}}>
        <Line data={DebthData} options={{
          scales: {
            x: {
                suggestedMin: 0,
            },
            y: {
              suggestedMin: 0,
              suggestedMax: 100
            } 
          }
        }}/> 
      </div>
    </Box>

    </Paper>
}
