import { Head } from '../components/head';
import { InteractiveTable } from '../ContentTable/table';

import {getStudents} from '../Utils/requests' 
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material'
import { StudentsChartsBar } from '../components/studentsChartsbar/studentsChartsBar';

async function getData(setData, Params={params: {}, optional: {}}, groupID){
  const Students = await getStudents({groupID: groupID, ...Params })
  setData(Students)
}  


// last column('') - for 'options' pic!
export const columns = [{colName: '', poleName: ""}, {colName: 'ФИО', poleName: "full_name", sortable: true}, {colName: '', poleName: ""}]
export const poles={
  to_fill: [{
    name: 'full_name', 
    placeholder: 'Имя студента',
  }],

  readyData: {
    group_id: 'to_fill_when_use',
  }
}



/* WORK_RENDER
return <div>
      <Head/>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', paddingLeft: '5rem', paddingRight: '5rem'}}>
        <InteractiveTable tableName={'Студенты ' + row.name} blockGo={true} AddToHead={goBack}
          style={{width: '40%'}}
          endpoint='students' columns={columns} poles={{...poles, readyData: {group_id: groupID}}}
          getData={(setData, params) => getData(setData, params, groupID)} 
        />     
        
        <StudentsChartsBar/>
      </Box>



    </div>
*/


export const ModStudentsPage = (props) => {
    const {row} = useLocation().state
    const crumbs = useLocation().pathname.split('/')
    const groupID = crumbs[crumbs.length-1]
    

    const redirect = useNavigate()
    const goBack = <Box 
        onClick={() => {redirect('/groups')}}>
        <Typography variant="h5" 
         color='#466EE3' 
         sx ={{cursor: 'pointer', "&:hover": {color: '#0C61F8'} }}>
            {"< Назад к группам"}
        </Typography>
    </Box>

    return <div>
      <Head/>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', paddingLeft: '5rem', paddingRight: '5rem'}}>
        <InteractiveTable tableName={'Студенты ' + row.name} blockGo={true} AddToHead={goBack}
          style={{width: '70%'}}
          endpoint='students' columns={columns} poles={{...poles, readyData: {group_id: groupID}}}
          getData={(setData, params) => getData(setData, params, groupID)} 
        />     
        
      </Box>



    </div>
    

}