import CryptoJS from 'crypto-js'



// gens colors(background + border) for given array
export const gen_color_palette = (data_arr, opacity=0.7) => {
    let hash = ''
    let pallete = {
      backGround: [],
      border: []
    }
    
    data_arr.map((el)=>{
      hash = CryptoJS.SHA256(JSON.stringify(el)).toString()
  
      // 0x_XY --> dec_XYZ - for rgba()
      let dec = []
      dec.push(parseInt(hash.slice(0,2), 16))
      dec.push(parseInt(hash.slice(2,4), 16))
      dec.push(parseInt(hash.slice(4,6), 16))
  
      pallete = { 
        backGround: [...pallete.backGround, `rgba(${dec[0]},${dec[1]},${dec[2]},${opacity})`],
        border:     [...pallete.border,     `rgba(${dec[0]},${dec[1]},${dec[2]},1)`]
      }
    })
    console.log('pallete:', pallete)
    return pallete
  }